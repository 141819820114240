import { _stringEnumKeyOrUndefined } from '@naturalcycles/js-lib'
import { T2_PRESALE_COUNTRIES } from '@naturalcycles/shared'
import { RegionSlug, ShippingLocations } from '@src/shop/cnst/shopify.cnst'
import { GeolocationCountries } from '@src/store/geolocation/geolocation.slice'
import { MouseEvent } from 'react'

export enum NavMenuMobilePlacements {
  MobileMenu = 'MobileMenu',
  MobileMenuExtra = 'MobileMenuExtra',
}

export interface NavMenuItem {
  id?: string
  text: string
  href: string
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}
export type NavMenu = NavMenuItem[]

export interface NavMenuLocaleUrls {
  [locale: string]: string
}

export const getShopMenu = (shippingLocation?: ShippingLocations, isMobile?: boolean): NavMenu => {
  const regionKeyOrBlocked = _stringEnumKeyOrUndefined(ShippingLocations, shippingLocation) || 'ROW'
  const regionSlug = regionKeyOrBlocked === 'Blocked' ? 'row' : RegionSlug[regionKeyOrBlocked]
  const commonPart = [
    {
      id: 'menu-all-products',
      text: 'All Products',
      href: `/shop/${regionSlug}`,
    },
    {
      id: 'shipping-information',
      text: 'Shipping Information',
      href: '/shop/page/shipping-information',
    },
    {
      id: 'menu-faq',
      text: 'FAQ',
      href: '/shop/page/faq',
    },
  ]

  const extra = [
    {
      id: 'menu-back',
      text: 'Go back to naturalcycles.com',
      href: '/',
    },
  ]

  return [...commonPart, ...(isMobile ? extra : [])]
}

export const getPressMenu = (translate: (key: string) => string, isMobile?: boolean): NavMenu => {
  const commonPart = [
    {
      text: translate('nav-news'),
      href: '/press-hub',
    },
    {
      text: translate('nav-companyinfo'),
      href: '/company-info',
    },
  ]

  const extra = [
    {
      text: translate('nav-presscontact'),
      href: 'mailto:press@naturalcycles.com',
    },
  ]

  return [...commonPart, ...(isMobile ? extra : [])]
}

export const getSiteMenu = (
  translate: (key: string) => string,
  locale: string,
  country: GeolocationCountries,
  isMobile?: boolean,
): NavMenu => {
  const url = (urls: NavMenuLocaleUrls): string => urls[locale]!

  // some languages make the header difficult to fit everything in because all
  // their words are always so long. we have to leave something out in these
  // cases so that it still looks okay.
  const isDifficultHeader = ['es-US', 'pt-BR'].includes(locale)

  const firstPart = [
    {
      id: 'menu-how-does-natural-cycles-work',
      text: translate('nav-explanation'),
      href: url({
        'en-US': '/how-does-natural-cycles-work',
        'es-US': '/es/how-does-natural-cycles-work',
        'pt-BR': '/pt-br/how-does-natural-cycles-work',
        'sv-SE': '/sv/how-does-natural-cycles-work',
        'en-CA': '/ca/how-does-natural-cycles-work',
      }),
    },
    {
      id: 'menu-is-natural-cycles-right-for-me',
      text: translate('nav-suitability'),
      href: url({
        'en-US': '/is-natural-cycles-right-for-me',
        'es-US': '/es/is-natural-cycles-right-for-me',
        'pt-BR': '/pt-br/is-natural-cycles-right-for-me',
        'sv-SE': '/sv/is-natural-cycles-right-for-me',
        'en-CA': '/ca/is-natural-cycles-right-for-me',
      }),
    },
    {
      id: 'menu-plan-pregnancy',
      text: translate('nav-plan'),
      href: url({
        'en-US': '/plan-pregnancy',
        'es-US': '/es/plan-pregnancy',
        'pt-BR': '/pt-br/plan-pregnancy',
        'sv-SE': '/sv/plan-pregnancy',
        'en-CA': '/ca/plan-pregnancy',
      }),
    },
  ]
  const secondPart: any[] = []

  if (T2_PRESALE_COUNTRIES.includes(country) || country === GeolocationCountries.US) {
    secondPart.push({
      id: 'menu-measuring-devices',
      text: translate('nav-measuring-devices'),
      href: url({
        'en-US': '/devices/eu',
        'es-US': '/es/devices/eu',
        'pt-BR': '/pt-br/devices/eu',
        'sv-SE': '/sv/devices/eu',
        'en-CA': '/ca/devices/eu',
      }),
    })
    if (isMobile) {
      secondPart.push({
        id: 'menu-testimonials',
        text: translate('nav-testimonials'),
        href: url({
          'en-US': '/reviews',
          'es-US': '/es/reviews',
          'pt-BR': '/pt-br/reviews',
          'sv-SE': '/sv/reviews',
          'en-CA': '/ca/reviews',
        }),
      })
    }
  } else {
    secondPart.push({
      id: 'menu-testimonials',
      text: translate('nav-testimonials'),
      href: url({
        'en-US': '/reviews',
        'es-US': '/es/reviews',
        'pt-BR': '/pt-br/reviews',
        'sv-SE': '/sv/reviews',
        'en-CA': '/ca/reviews',
      }),
    })
  }

  const thirdPart = [
    {
      id: 'menu-faqs',
      text: translate(isMobile ? 'nav-faqs-burger' : 'nav-faqs'),
      href: url({
        'en-US': '/faqs',
        'es-US': '/es/faqs',
        'pt-BR': '/pt-br/faqs',
        'sv-SE': '/sv/faqs',
        'en-CA': '/ca/faqs',
      }),
    },
  ]

  const fourthPart = [
    {
      id: 'menu-account-login',
      text: translate('nav-account'),
      href: url({
        'en-US': 'https://signup.naturalcycles.com/en-US/account/login',
        'es-US': 'https://signup.naturalcycles.com/es-US/account/login',
        'pt-BR': 'https://signup.naturalcycles.com/pt-BR/account/login',
        'sv-SE': 'https://signup.naturalcycles.com/sv-SE/account/login',
        'en-CA': 'https://signup.naturalcycles.com/ca/account/login',
      }),
    },
  ]

  return [
    ...firstPart,
    ...(isMobile || !isDifficultHeader ? secondPart : []),
    ...thirdPart,
    ...(isMobile ? fourthPart : []),
  ]
}
